<form [formGroup]="frmGroup" (ngSubmit)="save()">
  <div class="dialog-title">
    {{ "common.whatToChange" | translate }}
  </div>

  <mat-dialog-content>
    <div class="edit-card">
      <div class="title">
        {{ "common.carModel" | translate }}
      </div>
      <mat-form-field appearance="outline" floatLabel="never">
        <mat-select
          (infiniteScroll)="getNextBatch()"
          [complete]="isCompleted"
          msInfiniteScroll
          [placeholder]="'common.selectYourCarModel' | translate"
          formControlName="vehicleId"
        >
          <mat-option>
            <ngx-mat-select-search
              [placeholderLabel]="'common.carModelPH' | translate"
              [clearSearchInput]="false"
              [searching]="isLoading"
              [disableScrollToActiveOnOptionsChanged]="true"
              [ngModelOptions]="{ standalone: true }"
              noEntriesFoundLabel="'no matching car model found'"
              [(ngModel)]="searchText"
              (ngModelChange)="filterVehicle()"
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let item of vehicleList"
            [value]="item.id"
            (click)="selectVehicle = true"
          >
            {{ item.vehicle }}</mat-option
          >
        </mat-select>
        <mat-icon
          matSuffix
          *ngIf="this.frmGroup.value.vehicleId"
          (click)="clearVehicle()"
          >close</mat-icon
        >
      </mat-form-field>
    </div>

    <div class="sum-insured" *ngIf="partnerCode !== 'ktmb'">
      <div class="sum-insured__title">
        {{ "common.sumInsured" | translate }}
      </div>

      <span class="sum-insured__note">{{
        "common.sumInsuredNote" | translate
      }}</span>

      <app-range-slider
        [minError]="getSumInsuredConfig().minError"
        [maxError]="getSumInsuredConfig().maxError"
        [min]="getSumInsuredConfig().min"
        [max]="getSumInsuredConfig().max"
        formControlName="sumInsured"
      ></app-range-slider>
    </div>

    <div class="edit-card" *ngIf="partnerCode !== 'ktmb'">
      <div class="title">
        {{ "common.typeOfInsurance" | translate }}
      </div>
      <mat-form-field appearance="outline" floatLabel="never">
        <mat-select formControlName="insuranceType">
          <mat-option value="TP" *ngIf="data.request.insuranceType === 'TP'"
            >{{ "common.TP" | translate }}
          </mat-option>
          <mat-option value="TPFT">{{ "common.TPFT" | translate }}</mat-option>
          <mat-option value="COM">{{ "common.COM" | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="edit-card" *ngIf="showOtherInsurerInput">
      <div class="title">
        {{ "common.otherInsurers" | translate }}
      </div>

      <mat-form-field appearance="outline" floatLabel="never">
        <mat-select
          multiple
          formControlName="selectedMotorProductList"
          [placeholder]="'common.otherInsurersPH' | translate"
        >
          <mat-select-trigger>
            {{ getMotorProductDisplay() }}
          </mat-select-trigger>
          <mat-option>
            <ngx-mat-select-search
              [placeholderLabel]="'common.allInsurer' | translate"
              [ngModelOptions]="{ standalone: true }"
              [searching]="!motorProductList.length"
              [showToggleAllCheckbox]="true"
              [toggleAllCheckboxIndeterminate]="isIndeterminateMotorProduct()"
              [toggleAllCheckboxChecked]="isSelectedAllMotorProduct()"
              (toggleAll)="toggleSelectAllMotorProduct($event)"
              noEntriesFoundLabel="No matching insurer found"
              (ngModelChange)="filterMotorProductSelection($event)"
              ngModel
              ngDefaultControl
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let item of filterMotorProductList"
            [value]="item.id"
          >
            {{ item.name }}</mat-option
          >
        </mat-select>
        <mat-error
          >{{ "common.otherInsurers" | translate }}
          {{
            frmGroup.controls.selectedMotorProductList.errors
              | inputError
              | translate
          }}</mat-error
        >
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <button class="full-btn" mat-button [disabled]="isDisabledSubmit()">
    {{ "common.submit" | translate }}
  </button>
</form>
